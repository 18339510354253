var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AssetControlPanel,{attrs:{"asset":_setup.asset,"show-model":""}},[_c(_setup.AssetConfigForm,{attrs:{"asset":_setup.asset,"properties":[
      'system_status',
      'target_temperature',
      'temperature_tolerance',
      'fan_mode',
      'temperature_control_mode'
    ]}},[_c(_setup.ControlRow,[_c(_setup.PropertyValue,{class:_setup.nudgeDownExceptOnMobileClass,attrs:{"device":_setup.asset,"property":"ambient_temperature"}}),_c(_setup.ControlColumn,{class:_setup.xs ? 'w-100' : 'small-field'},[_c(_setup.FormField,{attrs:{"name":"target_temperature","single-line":"","hide-details":""}}),_c(_setup.FormField,{attrs:{"name":"system_status","single-line":"","hide-details":""}})],1),_c(_setup.ControlColumn,{class:_setup.xs ? 'w-100' : 'medium-field'},[_c(_setup.FormField,{attrs:{"name":"temperature_control_mode","hide-details":""}}),_c(_setup.FormField,{attrs:{"name":"fan_mode","hide-details":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }